<template>
  <div class="main-container">
    <p class="rem44 c-333333 mt30 f500">请输入验证码</p>
    <p class="c-8E929B rem30 mt10 word-space">
      验证码已发送至<span class="c-333333"> {{ newMoblie }} </span>
    </p>
    <input-code @confirm="confirm"></input-code>
    <div class="mt20">
      <span class="rem30 code" @click="getCode" v-if="countDown == 0">{{ codetext }}</span>
      <span class="rem30 code" v-else>{{ countDown }}s</span>
    </div>
  </div>
</template>
<script>
import inputCode from './user-input-code.vue'

export default {
  props: {
    newMoblie: {
      type: String,
    },
  },
  components: {
    inputCode,
  },
  data() {
    return {
      countDown: 0,
      codetext: '重新发送验证码',
    }
  },
  created() {},
  computed: {},
  methods: {
    getCode() {
      this.$emit('getCode')
      this.countDown = 60
      this.timer = setInterval(() => {
        this.countDown -= 1
        if (this.countDown === 0) {
          clearInterval(this.timer)
          this.codetext = '重新发送验证码'
        }
      }, 1000)
    },
    confirm(verifyCode) {
      this.$emit('confirm', verifyCode)
    },
  },
}
</script>
<style lang="less" scoped>
.main-container {
  height: 100%;
  background-color: #f5f6fa;
}
.code {
  color: #2e78fb;
}
.word-space {
  letter-spacing: 0.04rem;
}
</style>
