<template>
  <div>
    <div class="keyboard">
      <van-password-input
        v-model="value"
        :mask="false"
        gutter="0.3rem"
        :length="6"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />
      <van-number-keyboard v-model="value" :show="showKeyboard" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { PasswordInput, NumberKeyboard } from 'vant'

Vue.use(PasswordInput)
Vue.use(NumberKeyboard)
export default {
  data() {
    return {
      showKeyboard: false,
      value: '',
    }
  },
  created() {},
  computed: {},
  methods: {
    // 获取新手机的验证码
    getNewPhoneVerifyCode() {
      this.$emit('confirm', this.value)
    },
  },
  watch: {
    value(value) {
      if (value.length > 5) {
        value = value.substr(0, 6)
        this.getNewPhoneVerifyCode()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.keyboard {
  margin-top: 1.04rem;
}
.van-password-input__security li {
  background-color: #f5f6fa;
  border: none;
  border-bottom: 0.02rem solid #979797;
  font-size: 0.52rem;
}
.van-password-input__cursor {
  background-color: #2e78fb;
  width: 0.04rem;
}
.van-password-input {
  margin: 0;
}
</style>
