<template>
  <div class="login-container">
    <div class="login">
      <div class="c-333333 rem44">重置手机号</div>
      <div class="c-8E929B rem30 mt10">请输入新的手机号</div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="newMobile"
            class="username"
            :left-icon="phonePng"
            placeholder="请输入新的手机号"
            type="number"
          />

          <div class="mt40">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              :disabled="!newMobile"
              @click="nextStep"
            >
              下一步
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Form, Field, Button, Radio, RadioGroup } from 'vant'

Vue.use(Form)
  .use(Field)
  .use(Button)
  .use(Radio)
  .use(RadioGroup)
export default {
  data() {
    return {
      newMobile: '',
      phonePng: require('@/assets/images/user/sj.png'),
    }
  },
  created() {},
  computed: {},
  methods: {
    onSubmit() {},
    nextStep() {
      // 验证
      this.$emit('nextStep', this.newMobile)
    },
  },
}
</script>
<style lang="less" scoped>
.login-container {
  height: 100vh;
  background-color: #f5f6fa;
  padding: 0 0.52rem 0 0.48rem;
  .login {
    padding-top: 1.52rem;
    .username {
      margin-top: 0.68rem;
    }
  }
}
/deep/ .van-field__value {
  display: inherit;
}
/deep/ .van-field__value:before {
  content: '|';
  display: inline-block;
  height: 0.28rem;
  width: 1px;
  color: #4d7bfe;
  margin-right: 0.24rem;
}
/deep/ .van-field__left-icon {
  margin-right: 0.14rem;
}
.van-cell {
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.12rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
}
.van-button--normal {
  font-size: 0.34rem;
}
/deep/ .van-field__left-icon .van-icon {
  font-size: 0.48rem;
}
</style>
