<template>
  <div class="login-container">
    <div class="login" v-if="!resetFlag && !confirmFlag">
      <div class="c-333333 rem44">身份验证</div>
      <div class="c-8E929B rem30 mt10">请验证您的手机号</div>
      <div>
        <van-form @submit="onSubmit">
          <van-field
            v-model="currentMobile"
            class="username"
            readonly
            :left-icon="phonePng"
            placeholder="请输入注册手机号"
            type="number"
          />
          <van-field
            v-model="verifyCode"
            :left-icon="codePng"
            type="number"
            placeholder="请输入短信验证码"
            class="mt20"
          >
            <template #extra>
              <span class="rem28 c-4D7BFE" @click="getCode" v-if="countDown == 0">
                {{ codetext }}
              </span>
              <span class="rem28 c-4D7BFE" v-else>{{ countDown }}s</span>
            </template>
          </van-field>
          <div class="mt40">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              :disabled="!currentMobile || !verifyCode"
              @click="nextStep"
            >
              下一步
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <div class="reset" v-if="resetFlag">
      <resetPhone @nextStep="handleGetNewPhoneCode"></resetPhone>
    </div>
    <div class="confirm" v-if="confirmFlag">
      <newPhoneConfirm
        @confirm="handleConfirmChange"
        @getCode="handleGetNewPhoneCode"
        :newMoblie="newMoblie"
      />
    </div>
    <van-overlay :show="loading" @click="loading = false">
      <div class="wrapper" @click.stop>
        <van-loading size="24px">请稍后...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Vue from 'vue'
import { Form, Field, Button, Radio, RadioGroup, Loading, Overlay } from 'vant'
// comfirmUpdate
import { getVerifyCodeApi, checkOldPhoneApi, comfirmUpdateApi } from '@/apis/userCenter/index'
import resetPhone from './resetPhone.vue'
import newPhoneConfirm from './change-phone.vue'
import { Decrypt } from '@/utils/crypto'

Vue.use(Form)
  .use(Field)
  .use(Button)
  .use(Radio)
  .use(RadioGroup)
  .use(Overlay)
  .use(Loading)
export default {
  data() {
    const srcAssets = {
      phonePng: require('@/assets/images/login/password.png'),
      codePng: require('@/assets/images/login/code.png'),
      notChoosePng: require('@/assets/images/index/notChoose.png'),
      choosedPng: require('@/assets/images/index/choosed.png'),
    }
    return {
      ...srcAssets,
      currentMobile: '',
      verifyCode: '',
      codetext: '获取验证码',
      countDown: 0,
      resetFlag: false,
      confirmFlag: false,
      newMoblie: '',
      loading: false,
    }
  },
  components: {
    resetPhone,
    newPhoneConfirm,
  },
  created() {
    const { phone } = this.$route.query
    this.currentMobile = Decrypt(phone)
  },
  computed: {},
  methods: {
    onSubmit() {},
    // 获取身份验证验证码
    getCode() {
      const query = {
        codeType: 'oldMobile',
        ip: '',
        mobile: this.currentMobile,
      }
      getVerifyCodeApi(query).then(() => {})
      this.countDown = 60
      this.timer = setInterval(() => {
        this.countDown -= 1
        if (this.countDown === 0) {
          clearInterval(this.timer)
          this.codetext = '重新获取'
        }
      }, 1000)
    },
    // 确认身份验证
    nextStep() {
      const query = {
        code: this.verifyCode,
        mobile: this.currentMobile,
      }
      checkOldPhoneApi(query).then(() => {
        this.resetFlag = true
      })
    },
    // 获取修改手机号的验证码
    handleGetNewPhoneCode(newPhone) {
      this.newMoblie = this.newMoblie ? this.newMoblie : newPhone
      const query = {
        codeType: 'newMobile',
        ip: '',
        mobile: this.newMoblie,
      }
      getVerifyCodeApi(query).then(() => {
        this.resetFlag = false
        this.confirmFlag = true
      })
    },
    handleConfirmChange(verifyCode) {
      const query = {
        code: verifyCode,
        mobile: this.newMoblie,
      }
      this.loading = true
      comfirmUpdateApi(query)
        .then(() => {
          this.loading = false
          this.$router.push('/success')
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.login-container {
  height: 100vh;
  background-color: #f5f6fa;
  padding: 0 0.52rem 0 0.48rem;
  .login {
    padding-top: 1.52rem;
    .username {
      margin-top: 0.68rem;
    }
  }
}
/deep/ .van-field__value {
  display: inherit;
}
/deep/ .van-field__value:before {
  content: '|';
  display: inline-block;
  height: 0.28rem;
  width: 1px;
  color: #4d7bfe;
  margin-right: 0.24rem;
}
/deep/ .van-field__left-icon {
  margin-right: 0.14rem;
}
.van-cell {
  box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.12rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
}
.van-button--normal {
  font-size: 0.34rem;
}
/deep/ .van-field__left-icon .van-icon {
  font-size: 0.48rem;
}
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
